import axios from "axios";
import store from "@/store/index.js";
import { BASE_URL, API_URL, IMG_URL } from "../../env.js";
import { Message } from "element-ui";
import router from "../router";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 1000,
  //headers: { 'X-Custom-Header': 'foobar' },
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token")||null;
    // if (config.auth && !token) {
    //   store.dispatch("showAuthModal");
    //   throw `暂未登录,已阻止此次API请求: '${config.url}'`;
    // } else {
    //   // config.headers.token = token;
    //   config.headers.Authorization = token;
    // }
    // console.log(" this.$route.path this.$route.path---", window.location.hash);
    // console.log(" token", token);
    if (
      !token&&
      window.location.hash != "#/" &&
      window.location.hash != "#/login"
    ) {
      router.replace({
        path: "/login",
        replace: true,
      });
      throw `暂未登录,已阻止此次API请求: '${config.url}'`;
    } else {
      // config.headers.token = token;
      config.headers.Authorization = token;
    }

    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (response.data.code) {
      switch (response.data.code) {
        case 400:
          // 处理401错误，比如跳转到登录页面
          // console.log("1231", response.data.msg);
          Message.error(response.data.msg);
          // store.dispatch('showAuthModal');
          break;
        case 403:
          // 处理403错误
          break;
        default:
          // 处理其他响应错误
          break;
      }
    }
    return response.data;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          // 处理401错误，比如跳转到登录页面
          Message.error(error.response.data.msg);
          // store.dispatch('showAuthModal');
          break;
        case 401:
          Message.error(error.response.data.msg);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          router.replace({
            path: "/login",
            replace: true,
          });
          break;
          case 510:
            Message.error(error.response.data.msg);
            break;
        default:
          // 处理其他响应错误
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
