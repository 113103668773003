<template>
  <div>
    <div class="headers">
      <div class="containerBox">
        <div
          class="header flex align-items"
          style="justify-content: space-between"
        >
          <div class="left flex align-items" style="cursor: pointer;" @click="toHome" >
            <img
              class="img"
              src="../assets/img/sssssssss/logoBlack.png"
              alt=""
            />
          </div>
          <div class="right flex align-items">
            <nav class="nav">
              <div
                @mouseenter="handleMouseEnter(index)"
                class="nav-itemBox"
                @mouseleave="handleMouseLeave(index)"
                v-for="(item, index) in navList"
                :key="item.id"
              >
                <router-link
                  :to="{
                    path:
                      index !== 1 && item.path !== '/information'
                        ? item.path
                        : '',
                  }"
                  class="nav-item"
                  :class="{
                    active:
                      index != 0 &&
                      ((item.copy && $route.path.indexOf(item.copy) > -1) ||
                        $route.path.indexOf(item.path) > -1),
                  }"
                >
                  <div>{{ item.name }}</div>
                  <div
                    class="childNavMeun"
                    v-show="
                      item.show && item.children && item.children.length > 0
                    "
                  >
                    <div
                      class="childNavMeunList"
                      v-for="(items, indexs) in item.children"
                      :key="items.id"
                    >
                      <router-link
                        v-if="index==2"
                        :style="
                          String(path).indexOf(items.id) != -1
                            ? 'color: #00d8c3;'
                            : ''
                        "
                        class="childNavMeunLink"
                        :to="{ path: items.path }"
                        >{{ items.name }}</router-link
                      >
                      <router-link
                        v-else
                        :style="
                          String(path).indexOf(items.path) != -1
                            ? 'color: #00d8c3;'
                            : ''
                        "
                        class="childNavMeunLink"
                        :to="{ path: items.path }"
                        >{{ items.name }}</router-link
                      >
                    </div>
                  </div>
                </router-link>
              </div>
            </nav>
            <login-menu ref="loginMenu" :transparent="false" />
          </div>
        </div>
      </div>
    </div>
    <div class="default"></div>
  </div>
</template>

<script>
import loginMenu from "@/components/login-menu";

import { videoClassifyList } from "@/api/lx";
export default {
  components: {
    loginMenu,
  },
  data() {
    return {
      path: window.location.hash,
      navList: [
        { id: 1, name: "首页", path: "/" },
        {
          id: 2,
          name: "资料获取",
          path: "/information",
          show: false,
          children: [
            { id: 9, name: "产品手册", path: "/information/productManual" },
            { id: 10, name: "解决方案", path: "/information/solution" },
            { id: 11, name: "市场业绩", path: "/information/achievement" },
            { id: 12, name: "软件及工具", path: "/information/tool" },
          ],
        },
        {
          id: 3,
          name: "培训学院",
          path: "/school/index",
          show: false, 
          children: [],
        },
        { id: 4, name: "服务权益", path: "/service/index" },
        { id: 5, name: "社区联盟", path: "/community/index" },
        { id: 6, name: "常见问题", path: "/forum/index" },
        { id: 7, name: "积分商城", path: "/shop/index" },
      ],
    };
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getVideoClassifyList()
  },
  methods: {
    toHome(){
      this.$router.push("/")
    },

    getVideoClassifyList() {
      videoClassifyList({}).then((res) => {
        res.data.map((ele)=>{
          ele.path = "/school/index?id="+ele.id
        })
        this.navList[2].children = res.data
      });
    },
    refresh() {
      this.$refs.loginMenu.refresh();
    },
    // 鼠标移入方法
    handleMouseEnter(index) {
      if(index===1||index===2){
        this.navList[1].show = false
        this.navList[2].show = false
        this.navList[index].show = true
      }
    },
    handleMouseLeave(index) {
      this.navList[1].show = false
        this.navList[2].show = false
    },
  },
};
</script>

<style scoped lang="less">
.containerBox{
  width: 1800px;
  margin: 0 auto;
}
.default {
  width: 100%;
  height: 74px;
}
.headers {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .img {
    height: 36px;
    width: 330px;
  }
}
.nav {
  display: flex;
  align-items: center;
  margin-right: 50px;
  .nav-itemBox {
    padding: 0 25px;
    .nav-item {
      position: relative;
      text-align: center;
      color: #00161a;
      display: block;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      font-size: 18px;
      line-height: 74px;
      h3 {
        font-size: 18px;
      }
    }
    .active div {
      color: #00d8c3 !important;
    }
  }
}
.childNavMeun {
  position: absolute;
  top: 74px;
  left: -57px;
  background-color: #fff;
  width: 175px;
  padding: 5px 0;
  z-index: 999;
  .childNavMeunList {
    line-height: 40px !important;
    .childNavMeunLink {
      cursor: pointer;
      font-size: 16px;
      color: #00161a;
      text-decoration: none;
    }
  }
}

/deep/.header-custom-btn {
  color: #2e3033;
  border-color: transparent;
  background-color: transparent;

  a {
    color: initial;
    text-decoration: none;
  }
}

/deep/.user-info {
  .el-dropdown-link {
    color: #2e3033;
    cursor: pointer;
  }
}
</style>