import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "Layout" */ "@/views/Layout"),
    children: [
      {
        path: "/",
        name: "home",
        title: "首页",
        meta: {
          keepAlive: false,
          requireLogin: false,
        },
        component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "登录",
    },
    component: () => import("@/views/Login"),
  },
  {
    path: "/information",
    name: "information",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "产品手册",
    },
    // component: () => import("@/views/information/productManual"),
  },
  {
    path: "/information/productManual",
    name: "informationProductManual",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "产品手册",
    },
    component: () => import("@/views/information/productManual"),
  },
  {
    path: "/information/productManualDetails",
    name: "informationProductManualDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "产品手册详情",
    },
    component: () => import("@/views/information/productManualDetails"),
  },
  {
    path: "/information/solution",
    name: "informationSolution",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "解决方案",
    },
    component: () => import("@/views/information/solution"),
  },
  {
    path: "/information/solutionDetails",
    name: "informationSolutionDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "解决方案",
    },
    component: () => import("@/views/information/solutionDetails"),
  },
  {
    path: "/information/achievement",
    name: "informationAchievement",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "市场业绩",
    },
    component: () => import("@/views/information/achievement"),
  },
  {
    path: "/information/achievementDetails",
    name: "informationAchievementDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "市场业绩详情",
    },
    component: () => import("@/views/information/achievementDetails"),
  },
  {
    path: "/information/tool",
    name: "informationTool",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "软件或工具",
    },
    component: () => import("@/views/information/tool"),
  },
  {
    path: "/information/toolDetails",
    name: "informationToolDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "软件或工具",
    },
    component: () => import("@/views/information/toolDetails"),
  },
  {
    path: "/school/index",
    name: "schoolIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "培训学校",
    },
    component: () => import("@/views/school/index"),
  },
  {
    path: "/school/details",
    name: "schoolDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "培训学校",
    },
    component: () => import("@/views/school/details"),
  },
  {
    path: "/forum/index",
    name: "forumIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "在线论坛",
    },
    component: () => import("@/views/forum/index"),
  },
  {
    path: "/forum/details",
    name: "forumDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "在线论坛详情",
    },
    component: () => import("@/views/forum/details"),
  },
  {
    path: "/community/index",
    name: "communityIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "社区联盟",
    },
    component: () => import("@/views/community/index"),
  },
  {
    path: "/shop/index",
    name: "shop",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "积分商城",
    },
    component: () => import("@/views/shop/index"),
  },
  {
    path: "/service/index",
    name: "serviceIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "服务权益",
    },
    component: () => import("@/views/service/index"),
  },
  {
    path: "/my/index",
    name: "myIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "个人中心",
    },
    component: () => import("@/views/my/index"),
  },
  {
    path: "/uploadPwd/index",
    name: "uploadPwdIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "修改密码",
    },
    component: () => import("@/views/uploadPwd/index"),
  },
  {
    path: "/pdf/index",
    name: "pdfIndex",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "修改密码",
    },
    component: () => import("@/views/pdf/index"),
  },

  

  {
    path: "/products",
    name: "products",
    meta: {
      keepAlive: false,
      requireLogin: true,
      title: "全部装备",
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/products/Index"),
  },
  {
    path: "/products/filter",
    name: "productsFilter",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "产品筛选",
    },
    component: () =>
      import(
        /* webpackChunkName: "productsFilter" */ "@/views/products/Filter"
      ),
  },
  {
    path: "/products/details/:id",
    name: "productsDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "装备详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "productsDetails" */ "@/views/products/Details"
      ),
  },
  {
    path: "/products/cart/:id",
    name: "productsCart",
    meta: {
      keepAlive: false,
      requireLogin: true,
      title: "购物车",
    },
    component: () =>
      import(/* webpackChunkName: "productsDetails" */ "@/views/products/Cart"),
  },
  {
    path: "/supplier",
    name: "supplier",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "全部装备",
    },
    component: () =>
      import(/* webpackChunkName: "supplier" */ "@/views/supplier/Index"),
  },
  {
    path: "/supplier/details/:id",
    name: "supplierDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "装备详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "supplierDetails" */ "@/views/supplier/Details"
      ),
  },
  {
    path: "/comparison",
    name: "comparison",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "产品对比",
    },
    component: () =>
      import(/* webpackChunkName: "comparison" */ "@/views/comparison/Index"),
  },
  {
    path: "/biaoxun",
    name: "biaoxun",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "最新资讯",
    },
    component: () =>
      import(/* webpackChunkName: "biaoxun" */ "@/views/biaoxun/Index"),
  },
  {
    path: "/biaoxun/details",
    name: "biaoxunDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "执门标讯详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "productsDetails" */ "@/views/biaoxun/details"
      ),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "最新资讯",
    },
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/news/Index"),
  },
  {
    path: "/news/details/:id",
    name: "newsDetails",
    meta: {
      keepAlive: false,
      requireLogin: false,
      title: "执门标讯详情",
    },
    component: () =>
      import(/* webpackChunkName: "newsDetails" */ "@/views/news/details"),
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/index"),
    children: [
      {
        path: "",
        redirect: "profile",
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "用户资料",
        },
        component: () =>
          import(/* webpackChunkName: "userIndex" */ "@/views/user/profile"),
      },
      {
        path: "changePassword",
        name: "changePassword",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "修改密码",
        },
        component: () =>
          import(
            /* webpackChunkName: "userIndex" */ "@/views/user/changePassword"
          ),
      },
      {
        path: "settled",
        name: "settled",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "供应商入驻",
        },
        component: () =>
          import(/* webpackChunkName: "userSettled" */ "@/views/user/settled"),
      },
      {
        path: "publish",
        name: "publish",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "我的发布",
        },
        component: () =>
          import(/* webpackChunkName: "userSettled" */ "@/views/user/publish"),
      },
      {
        path: "orders",
        name: "orders",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "我的订单",
        },
        component: () =>
          import(/* webpackChunkName: "userSettled" */ "@/views/user/orders"),
      },
      {
        path: "message",
        name: "message",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "我的信息",
        },
        component: () =>
          import(/* webpackChunkName: "userSettled" */ "@/views/user/message"),
      },
      {
        path: "address",
        name: "address",
        meta: {
          keepAlive: false,
          requireLogin: true,
          title: "我的地址",
        },
        component: () =>
          import(/* webpackChunkName: "userAddress" */ "@/views/user/address"),
      },
    ],
  },
  {
    path: "*", // 通配符，匹配所有路由
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/components/NotFound.vue"),
  },
];

const router = new VueRouter({
  //mode: 'history',
  routes,
});

// router/index.js
router.beforeEach((to, from, next) => {
  if (to.matched.some((res) => res.meta.requireLogin)) {
    // 判断是否需要登录权限
    if (!window.localStorage.getItem("token")) {
      Message({
        showClose: true,
        message: "登录状态信息过期,请重新登录",
        type: "error",
      });
      next({
        path: "/",
      });
      store.dispatch("showAuthModal");
    } else {
      // 路由需要登录权限，并且已经登录，可以直接访问
      next();
    }
  } else {
    // 无需登录权限，直接访问
    next();
  }
  document.title = to.meta.title ? to.meta.title + "-良盟|良信合作伙伴联盟" : "良盟|良信合作伙伴联盟";
});

export default router;
