import request from '../utils/request';

// 获取登录验证码
export function getAuthCaptcha(data) {
  return request({
    auth: false,
    url: '/pc/auth/captcha',
    method: 'post',
    data,
    responseType: 'blob',
  });
}

// 手机号密码登录
export function authPassword(data) {
  return request({
    auth: false,
    url: '/pc/auth/password',
    method: 'post',
    data
  });
}

// 忘记密码-提交
export function passwordForget(data) {
  return request({
    auth: false,
    url: '/pc/auth/password/forget',
    method: 'post',
    data
  });
}

// 忘记密码-获取短信验证码
export function passwordCodeGet(data) {
  return request({
    auth: false,
    url: '/pc/auth/forget/password/code/get',
    method: 'post',
    data
  });
}

// 用户协议和隐私政策
export function agreementView(data) {
  return request({
    auth: false,
    url: '/pc/home/agreement/view',
    method: 'get',
    data
  });
}
//我想加入-提交
export function joinCommit(data) {
  return request({
    auth: false,
    url: '/pc/home/join/commit',
    method: 'post',
    data
  });
}
//底部参数
export function bottomParam(data) {
  return request({
    auth: false,
    url: '/pc/home/bottom/param',
    method: 'get',
    data
  });
}
//中部部分数据
export function centerView(data) {
  return request({
    auth: false,
    url: '/pc/home/center/view',
    method: 'get',
    data
  });
}
//解决方案-列表
export function solutionList(params) {
  return request({
    auth: false,
    url: '/pc/info/solution/list',
    method:'get',
    params,
  });
}
//解决方案-列表详情
export function solutionDetials(params) {
  return request({
    auth: false,
    url: '/pc/info/solution/view/'+params,
    method:'get',
    params,
  });
}

//市场业绩-分类
export function marketClassifyList(params) {
  return request({
    auth: false,
    url: '/pc/info/market/classify/list',
    method:'get',
    params,
  });
}


//市场业绩-列表
export function marketList(params) {
  return request({
    auth: false,
    url: '/pc/info/market/list',
    method:'get',
    params,
  });
}

//市场业绩-详情
export function marketView(params) {
  return request({
    auth: false,
    url: '/pc/info/market/view/'+params,
    method:'get',
    params,
  });
}

//软件或工具-分类
export function softwareClassifyList(params) {
  return request({
    auth: false,
    url: '/pc/info/software/classify/list',
    method:'get',
    params,
  });
}

//软件或工具-列表
export function softwareList(params) {
  return request({
    auth: false,
    url: '/pc/info/software/list',
    method:'get',
    params,
  });
}

//软件或工具列表-详情
export function softwareView(params) {
  return request({
    auth: false,
    url: '/pc/info/software/view/'+params,
    method:'get',
    params,
  });
}

//培训学院-分类列表
export function videoClassifyList(params) {
  return request({
    auth: false,
    url: '/pc/video/classify/list',
    method:'get',
    params,
  });
}
//培训学院-权益列表
export function videoEquityList(params) {
  return request({
    auth: false,
    url: '/pc/video/equity/classify/list',
    method:'get',
    params,
  });
}

//培训学院-视频列表
export function videoList(params) {
  return request({
    auth: false,
    url: '/pc/video/list',
    method:'get',
    params,
  });
}

//培训学院-详情
export function videoView(params) {
  return request({
    auth: false,
    url: '/pc/video/view/'+params,
    method:'get',
  });
}


//权益分类
export function equityClassify(params) {
  return request({
    auth: false,
    url: '/pc/equity/classify',
    method:'get',
    params,
  });
}

//权益列表
export function equityList(params) {
  return request({
    auth: false,
    url: '/pc/equity/list',
    method:'get',
    params,
  });
}

//联盟成员
export function factoryList(params) {
  return request({
    auth: false,
    url: '/pc/factory/list',
    method:'get',
    params,
  });
}
//论坛-问题列表
export function qaList(params) {
  return request({
    auth: false,
    url: '/pc/qa/list',
    method:'get',
    params,
  });
}
//论坛-详情
export function qaView(params) {
  return request({
    auth: false,
    url: '/pc/qa/view/'+params,
    method:'get',
    params,
  });
}
//用户信息
export function userDetail(params) {
  return request({
    auth: false,
    url: '/pc/user/detail',
    method:'get',
    params,
  });
}
//企业信息
export function userCompany(params) {
  return request({
    auth: false,
    url: '/pc/user/company',
    method:'get',
    params,
  });
}

//员工管理-列表
export function userStaffList(params) {
  return request({
    auth: false,
    url: '/pc/user/staff/list',
    method:'get',
    params,
  });
}
//员工管理-新增
export function userStaffAdd(data) {
  return request({
    auth: false,
    url: '/pc/user/staff/add',
    method:'post',
    data,
  });
}
//员工管理-编辑
export function userStaffUpdate(data) {
  return request({
    auth: false,
    url: '/pc/user/staff/update',
    method:'put',
    data,
  });
}

//修改密码
export function passwordUpdate(data) {
  return request({
    auth: false,
    url: '/pc/user/password/update',
    method:'put',
    data,
  });
}

//轮播图
export function banner(params) {
  return request({
    auth: false,
    url: '/pc/home/banner',
    method:'get',
    params,
  });
}

//产品手册-产品分类
export function productClassifyList(params) {
  return request({
    auth: false,
    url: '/pc/info/product/classify/list',
    method:'get',
    params,
  });
}

//产品手册-产品列表
export function productList(params) {
  return request({
    auth: false,
    url: '/pc/info/product/list',
    method:'get',
    params,
  });
}

//软件或工具列表-详情
export function productView(params) {
  return request({
    auth: false,
    url: '/pc/info/product/view/'+params,
    method:'get',
    params,
  });
}
/**
 * oss上传凭证
 * /web/index/button
 */
export function toolsts(params) {
  return request({
    auth: false,
    url: '/pc/tool/get/security/tokens',
    method:'get',
    params,
  });
}

//软件或工具列表-详情

export function avatarUpdate(data) {
  return request({
    auth: false,
    url: '/pc/user/avatar/update',
    method:'put',
    data,
  });
}

export function logOutapi(params) {
  return request({
    auth: false,
    url: '/pc/user/logout',
    method:'get',
    params,
  });
}